import React, {useEffect, useState} from 'react';
import { useParams } from "react-router-dom";
import ObraServices, { iObra } from '../../Services/ObraServices';
import { Navegation } from '../../Components/Navegation';
import GA from '../../GoogleAnalytics';
import WorkList from '../../Components/WorkList';
import Loader from '../../Components/Loader';
import './index.scss';
export default () => {
    const { id } = useParams<{id: string}>();
    //const [coleccion, setColeccion] = useState<iColeccion>({});
    const [total, setTotal ] = useState<number>(0);
    const [isPageLoading, setIsPageLoading] = useState<boolean>(true);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [workList, setWorkList ] = useState<iObra[]>([]);
    const [page, setPage ] = useState<number>(0);
    const [notFound, setNotFound] = useState<boolean>(false);
    useEffect(()=>{
        setIsPageLoading(true);
        getLoad(true)
    },[])
    
    function loadMore(){
        getLoad(false);
    }

    function getLoad(reset:boolean=false){
        setIsLoading(true);
        ObraServices.postSearch({descriptores_tematicos:id}, reset ? 0 : page +1).then( ({ data }: any) =>{
            const list:iObra[] = data?.data
            const total:number = data.pagination?.total
            if(list.length > 0){
                setTotal(total)
                setPage(page + 1);
                setWorkList(reset ? list : workList.concat(list));
            }else{
                setNotFound(true);
            }
        }).catch({}).finally(()=>{setIsLoading(false);setIsPageLoading(false);});
    }
    return (
        <div>
        {!isPageLoading && id? 
            <>
                <GA.RouteTracker title={`Descriptor: ${id}`}/>
                <Navegation title={`Descriptor: ${id}`} />

                <WorkList
                    className="p_tematico__result"
                    list={workList}
                    total={total}
                    notFound={notFound}
                    isLoading={isLoading}
                    handleLoadMore={loadMore}
                />
            </>
            :
            <Loader/>
        }
    </div>
    )
}