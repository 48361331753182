import React from 'react';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';

import './index.scss'

export default function main (props: any){
    return (
        <div className="l_main">
            <Header className="l_main__header"></Header>
            <div className="l_main__body">
                {props.children}
            </div>
            <div className="l_main__footer">
                <Footer></Footer>
            </div>
        </div>
    )
}