import React from 'react';

export interface iHeaderContext {
    showSearch: boolean
    setShowSearch: Function
}

export const HeadContext = React.createContext<iHeaderContext>({
    showSearch: false,
    setShowSearch: ()=>{}
});