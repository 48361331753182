import React from 'react';
import { useNavigate } from "react-router-dom";
import Loader from '../../Components/Loader';

import './index.scss';

interface iProps {
    theme: 'linear' | 'solid' | 'dark' | 'black';
    isLoading?: boolean;
    isDisabled?: boolean;
    link?: string ;
    prelink?: any ;
    action?: any ;
    className?: string ;
    children: JSX.Element | string;
}

export const Button = function (props:iProps) {
    const navigate = useNavigate();

    function actionClick () {
        if(props.action){
            props.action();
        }else if(props.link){
            if(props.prelink) props.prelink();
            navigate(props.link);   
        }
    }
    return (
        <button onClick={actionClick} disabled={props.isLoading || props.isDisabled} className={`c_button ${props.theme} e-p2 e-p3:sm e-p4:md ${props.className} ${props.isLoading && 'isLoading'}`}>    
            {!props.isLoading ?
                props.children
                :
                <Loader theme={'white'}/>
            }
        </button>
    )
}