import React, {useState, useEffect} from 'react';
import { Navegation } from '../../Components/Navegation';
import Loader from '../../Components/Loader';
import { useNavigate } from "react-router-dom";
import ColeccionList from '../../Components/ColeccionList';
import { routes } from '../../routeConfig';
import ColeccionServices, {iColeccion} from '../../Services/ColeccionServices';
import ParrafoServices, { iParrafo } from '../../Services/ParrafosServices';
import GA from '../../GoogleAnalytics';
import './index.scss';

export default () => {
    const navigate = useNavigate();
    const [listColeccion, setListColeccion ] = useState<iColeccion[]>([]);
    const [ isLoading , setIsLoading ] = useState(false);
    const [text, setText] = useState("")

    useEffect(() => {
        setIsLoading(true)
        ParrafoServices.getById("7").then((result: any)=> {
            setText(result.data[0]?.texto)
        }).finally(
            ColeccionServices.getAll().then(({data}:any) => {
                if(data.data){
                    setListColeccion(data.data.map((obj:iColeccion)=>({...obj,nombre:obj.titulo,portada:"/coleccion/"+obj.portada})));
                }
            }).finally(() => {
                setIsLoading(false)
            })
        )
        
    }, [])

    function goTo (id: number) {
        const coleccion = listColeccion.find((coleccion)=>coleccion.id===id)
        navigate(`/recurospedagogicos/${id}`);
    }

    return (
        <div>
            { isLoading ?
                <Loader/>
                :
                <>
                    <GA.RouteTracker title={`Recursos Pedagógicos`}/>
                    <Navegation title={`RECURSOS PEDAGÓGICOS`} />
                    {text ?
                        <div className="p_recursos__pharagraph e-p4 e-text-light" dangerouslySetInnerHTML={{__html: text}}></div>
                        :
                        <p className="p_recursos__pharagraph e-p4 e-text-light">
                        </p>
                    }
                    <ColeccionList listColeccion={listColeccion} isClicked={(id)=>{goTo(id)}}></ColeccionList>
                </>
            }
            
        </div>
    )
}