import React from 'react';
import GA from '../../GoogleAnalytics';
import { Navegation } from '../../Components/Navegation';
import Accordion, {iColeccion} from '../../Components/Accordion';
import { useNavigate } from "react-router-dom";
import './index.scss';
export default () => {
    const colecciones: Array<iColeccion> = [
        {
        nombre: "PREHISPÁNICO",
        codigo:"PRE",
        medios: 
            [
                {nombre:"ARQUITECTURA", codigo:"ARQ"},
                {nombre:"CERÁMICA", codigo:"CER"},
                {nombre:"MADERA", codigo:"MAD"},
                {nombre:"METAL", codigo:"MET"},
                {nombre:"PIEDRA / CONCHA", codigo:"CON"},
                {nombre:"TEXTIL", codigo:"TEX"},
            ]
        }, 
        {
            nombre: "Colonial",
            codigo:"COL",
            medios: 
                [
                    {nombre:"Arquitectura", codigo:"ARQ"},
                    {nombre:"Artes Decorativas / Mobiliarios", codigo:"ARD"},
                    {nombre:"Escultura / Ensamblaje", codigo:"ESC"},
                    {nombre:"Pintura", codigo:"PIN"},
                    {nombre:"Platería", codigo:"MET"},
                    {nombre:"Textil", codigo:"TEX"},
                ]
        },
        {
            nombre: "Republicano",
            codigo:"REP",
            medios: 
                [
                    {nombre:"Arquitectura", codigo:"ARQ"},
                    {nombre:"Artes Decorativas Platería", codigo:"ARD"},
                    {nombre:"Dibujo", codigo:"DIB"},
                    {nombre:"Escultura", codigo:"ESC"},
                    {nombre:"Fotografía", codigo:"FOT"},
                    {nombre:"Pintura", codigo:"PIN"},
                ]
        },
        {
            nombre: "MODERNO",
            codigo:"MOD",
            medios: 
                [
                    {nombre:"Dibujo", codigo:"DIB"},
                    {nombre:"Escultura", codigo:"ESC"},
                    {nombre:"Grabado", codigo:"GRA"},
                    {nombre:"Pintura", codigo:"PIN"},
                    {nombre:"Técnica Mixta", codigo:"TEM"},
                    {nombre:"Textil", codigo:"TEX"},
                ]
        },
        {
            nombre: "Contemporáneo",
            codigo:"CON",
            medios: 
                [
                    {nombre:"Ensamblaje / Instalación", codigo:"ENS"},
                    {nombre:"Litografía", codigo:"LIT"},
                    {nombre:"Escultura", codigo:"ESC"},
                    {nombre:"Dibujo", codigo:"DIB"},
                    {nombre:"Pintura", codigo:"PIN"},
                    {nombre:"Textil", codigo:"TEX"},
                ]
        }
    ]

    const navigate = useNavigate();

    function itemClicked(col:string,med:string) {
        navigate(`/coleccion/${col}/${med}`);
    }   
    return (
        <div>
            <GA.RouteTracker title={"Colecciones"}/>
            <Navegation title={"Colecciones"} />
            <div className="p_coleccion">
                {colecciones &&
                    colecciones.map((coleccion,index) =>
                        <Accordion 
                            key={index}
                            nombre={coleccion.nombre} 
                            codigo={coleccion.codigo} 
                            medios={coleccion.medios}
                            clicked={itemClicked}
                        ></Accordion>
                    )
                }
            </div>
        </div>
    )
}