import React, {useEffect,useState} from 'react';
import './index.scss';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ContentLoader from 'react-content-loader'
interface iProps {
    isSecondary?: boolean;
    title: string | undefined;
    banner: string | undefined;
    isClicked?:  () => void;
    isBlocked?: boolean;
}

export const Coleccion = function (props: iProps){
    const [isLoading,setIsLoading] = useState(true)
    useEffect(()=>{
        const img = new Image();
        img.src = `https://test.archi.pe/cdn/thumbs${props.banner}`;
        img.onload = () => {
            setIsLoading(false)
        };
    }, [])


    return (
        <div onClick={props.isBlocked ? ()=>{} : props.isClicked} className={`c_coleccion__container ${props.isBlocked ? 'c_coleccion__container__Blocked':''}`}>
            {isLoading ?
                <ContentLoader 
                    speed={4}
                    height={220}
                    backgroundColor="#f3f3f3"
                    foregroundColor="#ecebeb"   
                >
                    <rect x={0} y="10" rx="3" ry="3" width={400} height={220} />
                </ContentLoader>
            :
                <>
                    <LazyLoadImage effect="blur"  className="c_coleccion__image" src={`https://test.archi.pe/cdn/thumbs${props.banner}` } visibleByDefault={true}></LazyLoadImage>
                    <div className={`c_coleccion__layer secondary`}>
                        <p className={`c_coleccion__title secondary e-p1 e-p2:md e-text-medium`}>{props.title}</p>
                    </div>
                </>
            }
            
        </div>
    )
}