import {iObra, iFormSearch} from './ObraServices';

export interface iSearchResultStorage {
    form : iFormSearch,
    result:iObra[],
    total: number,
    page: number
}

export default class SearchResultPersistService {
    private nameStorage: string = "$.searchresult";
    public storage:iSearchResultStorage | undefined;
    private static _instance: SearchResultPersistService;

    private constructor (){}

    public static getInstance() {
        if (!SearchResultPersistService._instance) {
            SearchResultPersistService._instance = new SearchResultPersistService();
        }

        return SearchResultPersistService._instance;
    }

    public syncStorage(): iSearchResultStorage {
        try {
            this.storage = JSON.parse(localStorage.getItem(this.nameStorage)!)
        } catch (error) {
            console.warn("WARN: Storage Empty")
        } finally {
            return this.storage!
        }
    }

    public setForm(form : iFormSearch) {
        this.syncStorage()
        try {    
            this.storage = {...this.storage!, form}
            localStorage.setItem(this.nameStorage,JSON.stringify(this.storage));
        } catch (error) {
            console.warn(`WARN: ${error}`)
        }
    }

    public setResult(result : iObra[],total:number, page: number) {
        this.syncStorage()
        try {    
            this.storage = {...this.storage!, result, total,page}
            localStorage.setItem(this.nameStorage,JSON.stringify(this.storage));
        } catch (error) {
            console.warn(`WARN: ${error}`)
        }
    }

}