import React, {useEffect, useState} from 'react';
import './index.scss';
import { useParams } from "react-router-dom";
import Loader from '../../Components/Loader';
import { Navegation } from '../../Components/Navegation';
import ObraServices, { iObra } from '../../Services/ObraServices';
import GA from '../../GoogleAnalytics';
import WorkList from '../../Components/WorkList';

interface iColeccion {
    periodo?: {
        codigo: string,
        nombre: string
    }
    medio?: {
        codigo: string,
        nombre: string
    }
}

export default () => {
    const { col , med } = useParams<{col: string, med: string}>();
    const [coleccion, setColeccion] = useState<iColeccion>({});
    const [total, setTotal ] = useState<number>(0);
    const [isPageLoading, setIsPageLoading] = useState<boolean>(true);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [workList, setWorkList ] = useState<iObra[]>([]);
    const [page, setPage ] = useState<number>(0);
    const [notFound, setNotFound] = useState<boolean>(false);
    
    useEffect(()=>{
        setIsPageLoading(true);
        getLoad(true)
    },[])

    function loadMore(){
        getLoad(false);
    }

    function getLoad(reset:boolean=false){
        setIsLoading(true);
        ObraServices.getByPeriodoMedio(col!, med!, reset ? 0 : page + 1).then(({data}:any)=>{
            const {object, list} = data.data;
            const total:number = data.pagination?.total
            setTotal(total)
            if(reset){
                setColeccion(object);
            }
            if(list.length > 0){
                setPage(page + 1);
                setWorkList(reset ? list : workList.concat(list));
            }else{
                setNotFound(true);
            }
        }).catch(()=>{}).finally(()=>{
            setIsLoading(false)
            setIsPageLoading(false);
        })
    }

    return (
        <div>
            {!isPageLoading ? 
                <>
                    <GA.RouteTracker title={`${coleccion.periodo?.nombre}/${coleccion.medio?.nombre}`}/>
                    <Navegation title={`Coleccion ${coleccion.periodo?.nombre}`} />
                    <p className={"p_coleccion__medio e-p3"}>{coleccion.medio?.nombre}</p>

                    <WorkList
                        className="p_coleccion__result"
                        list={workList}
                        total={total}
                        notFound={notFound}
                        isLoading={isLoading}
                        handleLoadMore={loadMore}
                    />
                </>
                :
                <Loader/>
            }
        </div>
    )
}