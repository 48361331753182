import React, {useState, useEffect} from 'react';
import { Navegation } from '../../Components/Navegation';
import { useParams } from "react-router-dom";
import Loader from '../../Components/Loader';
import { useNavigate } from "react-router-dom";
import ColeccionList from '../../Components/ColeccionList';
import ResourceCollection, {iListRecursos} from '../../Components/ResourceCollection';
import ColeccionServices, {iColeccion} from '../../Services/ColeccionServices';
import RecursosServices, {iRecursos} from '../../Services/RecursosServices';
import { Coleccion } from '../../Components/Coleccion';
import GA from '../../GoogleAnalytics';
import './index.scss';

export default () => {
    const navigate = useNavigate();
    const [listResourceAlumno, setListResourceAlumno] = useState<iListRecursos>();
    const [listResourceDocente, setListResourceDocente] = useState<iListRecursos>();
    const [listColeccion, setListColeccion ] = useState<iColeccion[]>([]);
    const [coleccion, setColeccion ] = useState<iColeccion>();
    const [ isLoading , setIsLoading ] = useState(false);
    const [ exp , setExp ] = useState("");
    const { id } = useParams<{id: string}>();

    useEffect(() => {
        (async() => {
            setIsLoading(true)
            const {data} = await ColeccionServices.getById(+id!)
            if(data.data){
                const proyecto = data.data[0]
                setColeccion(proyecto)
                const exposiciones = proyecto?.subcolecciones;
                setListColeccion(exposiciones.map((obj:iColeccion)=>({...obj,nombre:obj.titulo,portada:"/proyecto/"+obj.portada})));
                if(exposiciones && exposiciones.length === 1){
                    const exposicion = exposiciones[0]
                    setExp(exposicion.id)
                    await loadContent(exposicion.id)
                    //setColeccion(exposicion)
                }
                setIsLoading(false)
            }
        })();
    }, [])

    function goTo (exp: number) {
        navigate(`/recurospedgogicos/exposicion/${id}/${exp}`);
    }

    async function loadContent (exposicion: number) {
        setListResourceAlumno({} as iListRecursos)
        setListResourceDocente({} as iListRecursos)
        const {data} = await RecursosServices.getByExposicion(exposicion)
        const listResourceAlumno:iListRecursos = {
            otro:data.data.filter((elm:iRecursos) => elm.tipo === "A" && (!elm.gradoAcademico || elm.gradoAcademico === "TOD")),
            inicial:data.data.filter((elm:iRecursos) => elm.tipo === "A" && elm.gradoAcademico === "INI"),
            primaria:data.data.filter((elm:iRecursos) => elm.tipo === "A" && elm.gradoAcademico === "PRI"),
            secundaria:data.data.filter((elm:iRecursos) => elm.tipo === "A" && elm.gradoAcademico === "SEC")
        } as iListRecursos
        setListResourceAlumno(listResourceAlumno)
        const listResourceDocente:iListRecursos = {
            otro:data.data.filter((elm:iRecursos) => elm.tipo === "D" && (!elm.gradoAcademico || elm.gradoAcademico === "TOD")),
            inicial:data.data.filter((elm:iRecursos) => elm.tipo === "D" && elm.gradoAcademico === "INI"),
            primaria:data.data.filter((elm:iRecursos) => elm.tipo === "D" && elm.gradoAcademico === "PRI"),
            secundaria:data.data.filter((elm:iRecursos) => elm.tipo === "D" && elm.gradoAcademico === "SEC")
        } as iListRecursos
        setListResourceDocente(listResourceDocente)
    }

    function resetCheckbox(){
        loadContent(+exp);
    }

    return (
        <div>
            { isLoading && !coleccion ?
            
                    <Loader/>
                    :
                    <>
                        <GA.RouteTracker title={`Recursos Pedagógicos : ${coleccion?.titulo}`}/>
                        <Navegation title={`${coleccion?.titulo}`} />
                        <p className="p_coleccion_educativa__pharagraph e-p4 e-text-light" dangerouslySetInnerHTML={{ __html:coleccion?.textoReferido || ''}}></p>
                        {listColeccion && listColeccion.length === 1 ?
                            <ResourceCollection reset={()=>{resetCheckbox()}} listResourceDocente={listResourceDocente} listResourceAlumno={listResourceAlumno}/>
                            :
                            <ColeccionList listColeccion={listColeccion} isClicked={(id)=>{goTo(id)}}></ColeccionList>
                        }
                    </>
            }
            
        </div>
    )
}