import React, {useState} from 'react';
import Summary from '../Summary';
import ReactGA from 'react-ga'
import RecursosServices, {iRecursos} from '../../Services/RecursosServices';
import { Button } from '../Button';
import './index.scss';

export interface iListRecursos {
    otro?:iRecursos[], 
    inicial?: iRecursos[], 
    primaria:iRecursos[], 
    secundaria: iRecursos[]
}

interface iProps {
    listResourceDocente?: iListRecursos,
    listResourceAlumno?: iListRecursos,
    reset:()=>void
}

export default (props: iProps) => {

    const [selectedItems, setSelectedItems] = useState<Array<Number>>([]);

    function changeInputCheckbox (event:any) {
        if(selectedItems.includes(event.target.value)){
            const index = selectedItems.indexOf(event.target.value);
            if (index > -1) {
                selectedItems.splice(index, 1);
                setSelectedItems([...selectedItems])
            }
        }else{
            setSelectedItems([...selectedItems,event.target.value])
        }
    }

    function download(){
        ReactGA.event({
            category: 'Recursos Educativos',
            action: 'DescargarZip',
            label: selectedItems.join(",")
        });
        window.open(process.env.REACT_APP_BASE_API + "/contenido/compress" + "?ids="+selectedItems.join(","));
        resetCheckbox()
    }

    function openItem(category: string, item:iRecursos){
        const {id, file, nombre ,formato} = item;
        if(formato === "pdf"){
            ReactGA.event({
                category: 'Recursos Educativos',
                action: 'Descargar - '+ category,
                label: id + ' - ' + nombre
            });
            window.open("http://www.api.archi.pe:8081/contents/contenido/"+ id + "_" +file, "_blank");
            resetCheckbox()
        } else if(formato) {
            ReactGA.event({
                category: 'Recursos Educativos',
                action: 'Link - '+ category,
                label: id + ' - ' + nombre
            });
            window.open(file, "_blank");
            resetCheckbox()
        }
    }
      
    function resetCheckbox(){
        setSelectedItems([])
        props.reset();
    }

    function isChecked(id:number){
        return selectedItems.includes(id)
    }

    const itemList = (props: any) => 
    <li className="c_resource_collection_list_item e-p4">
        <input name={props.name} disabled={props.res.formato!== "pdf"} type="checkbox" value={props.res.id} onChange={changeInputCheckbox}></input>
        {
            props.res.formato ?
            <a onClick={()=> {openItem(props.datalayer,props.res)}}>{props.res.nombre}</a>
            :
            <span>{props.res.nombre}</span>
        }
    </li>

    return (
    <>
        <div className="c_resource_collection">
            <Summary
                keepOpen={true}
                title={"RECURSOS PARA EL DOCENTE"} 
            >
                <div className="c_resource_collection_summary">
                    <ul className="c_resource_collection_list">
                        {props.listResourceDocente?.otro && props.listResourceDocente.otro.map((res:iRecursos, index) =>
                                itemList({name:"chckResourceDocente", res, datalayer: "Docente"})
                            )
                        }
                        {props.listResourceDocente?.inicial && props.listResourceDocente.inicial.length > 0 &&
                            <>
                                <p className="e-p2 e-text-medium">Inicial</p>
                                {props.listResourceDocente.inicial.map((res:iRecursos, index) =>
                                    itemList({name:"chckResourceDocente", res, datalayer: "Docente - Inicial"})
                                )}
                            </>
                        }

                        {props.listResourceDocente?.primaria && props.listResourceDocente.primaria.length > 0 &&
                            <>
                                <p className="e-p2 e-text-medium">Primaria</p>
                                { props.listResourceDocente?.primaria.map((res:iRecursos, index) =>
                                    itemList({name:"chckResourceDocente", res, datalayer: "Docente - Primaria"})
                                )
                                }
                            </>
                        }

                        {props.listResourceDocente?.secundaria && props.listResourceDocente.secundaria.length > 0 && 
                            <>
                                <p className="e-p2 e-text-medium">Secundaria</p>
                                {props.listResourceDocente.secundaria.map((res:iRecursos, index) =>
                                    itemList({name:"chckResourceDocente", res, datalayer: "Docente - Secundaria"}))
                                }
                            </>
                        }
                    </ul>

                </div>
            </Summary>
            <Summary 
                keepOpen={true} 
                title={"RECURSOS PARA EL AULA"} 
            >
                <div className="c_resource_collection_summary">
                    <ul className="c_resource_collection_list">
                        {props.listResourceAlumno?.otro && props.listResourceAlumno.otro.map((res:iRecursos, index) =>
                            itemList({name:"chckResourceDocente", res, datalayer: "Aula"})
                            )
                        }
                        {props.listResourceAlumno?.inicial && props.listResourceAlumno.inicial.length > 0 && 
                            <>
                                <p className="e-p2 e-text-medium">Inicial</p>
                                {props.listResourceAlumno.inicial.map((res:iRecursos, index) =>
                                    itemList({name:"chckResourceDocente", res, datalayer: "Aula - Inicial"})
                                    )
                                }
                            </>
                        }

                        {props.listResourceAlumno?.primaria && props.listResourceAlumno.primaria.length > 0 && 
                            <>
                                <p className="e-p2 e-text-medium">Primaria</p>
                                {props.listResourceAlumno.primaria.map((res:iRecursos, index) =>
                                        itemList({name:"chckResourceDocente", res, datalayer: "Aula - Primaria"})
                                    )
                                }
                            </>
                        }

                        {props.listResourceAlumno?.secundaria && props.listResourceAlumno.secundaria.length > 0 && 
                            <>
                                <p className="e-p2 e-text-medium">Secundaria</p>
                                {props.listResourceAlumno.secundaria.map((res:iRecursos, index) =>
                                    itemList({name:"chckResourceDocente", res, datalayer: "Aula - Secundaria"}))
                                }
                            </>
                        }   
                    </ul>
                </div>
            </Summary>
        </div>
        {selectedItems.length > 1 &&
            <Button theme="solid" action={download}>{`Descargar ${selectedItems.length > 0 ? selectedItems.length + " recursos seleccionados": ""}`}</Button>
        }
    </>
)
}