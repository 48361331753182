import React, {useEffect, useState}  from 'react';
import GA from '../../GoogleAnalytics'
import { CommonView } from '../../Layouts/CommonView';
import ParrafoServices, { iParrafo } from '../../Services/ParrafosServices';
import Loader from '../../Components/Loader';
import './index.scss'

export const ApoyaMali = function () {
    const [isLoading, setIsLoading] = useState(true)
    const [text, setText] = useState("")

    useEffect(()=>{
        ParrafoServices.getById("6").then((result: any)=> {
            setText(result.data[0]?.texto)
        }).finally(
            setIsLoading(false)
        )
    }, [])

    return (
        <div>
            {!isLoading ?
                <>
                    <GA.RouteTracker title={"Apoya al MALI"}/>
                    <CommonView title="Apoya al MALI" content={
                        text ?
                            <div dangerouslySetInnerHTML={{__html: text}}></div>
                            :
                            <>
                                <div>Hay muchas maneras en las que puedes formar parte de ARCHI. 
                                <br></br>
                                <br></br>
                                Proporcionándonos información adicional que tengas sobre la imagen de la obra que encuentras.Puede contactarnos a través del correo
                                archi@mali.pe   
                                <br></br>
                                <br></br> 
                                Comprando una fotografía en alta resolución. Si bien el acceso a las
                                imágenes en baja resolución es gratuito, el uso comercial de estas supone un
                                cobro para darle sostenibilidad al archivo. Invitamos a artistas, fotógrafos,
                                archivos e instituciones a formar parte de esta iniciativa.
                                <br></br><br></br>
                                Uniéndote al archivo enviándonos imágenes que pueden contribuir a enriquecer ARCHI.</div>
                            </>
                        }
                    />  
                </>
                :
                <Loader/>
            }                    
        </div>
    )
}