import React from 'react';
import { Button } from '../Button';
import { ReactComponent as CloseIcon } from '@Assets/icons/close.svg';
import './index.scss';

interface iProps {
    title?: JSX.Element;
    children?: React.ReactNode;
    buttons?: React.ReactNode;
    handleClose?: () => void;
    className?: string ;
}

export default (props: iProps) => {
    return (
        <div className="c_modal__layer">
            <div className={`c_modal ${props.className}`}>
                <div className="c_modal__header">
                    <div>{props.title}</div>
                    {props.handleClose &&
                        <CloseIcon onClick={props.handleClose} className="c_modal__close"/>
                    }
                </div>
                <div className="c_modal__body">
                    {props.children}
                </div>
                <div className="c_modal__footer">
                    {props.buttons}
                    
                </div>
            </div>
        </div>
    )
}