import api from './HttpUtil';

export interface iAutor {
    id: number,
    nombres: string,
    apellidos: string,
    seudonimo: string,
    tipo: string,
    fecha_creacion: string,
    fecha_modificacion: string,
    usuario_creacion: number,
    usuario_modificacion: number,
    activo: number
}


export default {
    getAll(){
        return api.get('/autor/all');
    },
    getById(id: string){
        return api.get('/autor/'+id);
    }
}