import api from './HttpUtil';
const axios = require('axios');

export interface iParrafo {
    id: number,
    nombre: string,
    texto: string
}


export default {
    getById(id: string){
        return axios.get('https://www.test.archi.pe/cdn/parrafo/obtener/'+id);
    }
}