import React, {useEffect, useState} from 'react';
import './index.scss';
import { useParams } from "react-router-dom";
import Loader from '../../Components/Loader';
import { Navegation } from '../../Components/Navegation';
import ObraServices, { iObra } from '../../Services/ObraServices';
import GA from '../../GoogleAnalytics';
import WorkList from '../../Components/WorkList';
interface iTematico {
    nombre?: string;
    portada?: string;
}
export default () => {
    const { id } = useParams<{id: string}>();
    const [tematico, setTematico] = useState<iTematico>({});
    const [isPageLoading, setIsPageLoading] = useState<boolean>(true);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [total, setTotal ] = useState<number>(0);
    const [workList, setWorkList ] = useState<iObra[]>([]);
    const [page, setPage ] = useState<number>(0);
    const [notFound, setNotFound] = useState<boolean>(false);

    useEffect(()=>{
        setIsPageLoading(true);
        getLoad(true)
    },[])

    function loadMore(){
        getLoad(false);
    }

    function getLoad(reset:boolean=false){
        setIsLoading(true);
        ObraServices.getByTematico(+id!, reset ? 0 : page + 1).then(({data}:any)=>{
            const {object, list} = data.data;
            const total:number = data.pagination?.total
            setTotal(total)
            if(reset && object.length > 0){
                setTematico(object[0]);
            }
            if(list.length > 0){
                setPage(page + 1);
                setWorkList(reset ? list : workList.concat(list));
            }else{
                setNotFound(true);
            }
        }).catch(()=>{}).finally(()=>{
            setIsLoading(false)
            setIsPageLoading(false);
        })
    }

    return (
        <div>
            {!isPageLoading && tematico? 
                <>
                    <GA.RouteTracker title={`Tematico: ${tematico.nombre}`}/>
                    <Navegation title={`${tematico.nombre}`} />

                    <WorkList
                        className="p_tematico__result"
                        list={workList}
                        total={total}
                        notFound={notFound}
                        isLoading={isLoading}
                        handleLoadMore={loadMore}
                    />
                </>
                :
                <Loader/>
            }
        </div>
    )
}