import api from './HttpUtil';

export interface iTematico {
    id: number;
    nombre: string;
    portada: string;
    publicar: number;
    orden: number;
}


const tematicoService = {
    getAll(){
        return api.get('/tematico/all')
    }
}

export default tematicoService;