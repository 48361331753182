import React from 'react';
import './index.scss';

interface iProps {
    theme?: 'white'|'purple';
    className?: string
}

export default (props: iProps) => {
    return (
        <div className={`c_loader ${props.theme || 'purple'} ${props.className}`}></div>
    )
}