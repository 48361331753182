import React from 'react';
import { Button } from '../Button';
import {ReactComponent as LogoMaliIcon} from '@Assets/icons/MALI_White.svg';
import {ReactComponent as MuseosLinea} from '@Assets/icons/MUSEOS_LINEA_H.svg';
import {ReactComponent as MovistarIcon} from '@Assets/icons/movistar.svg';
import {ReactComponent as EspacioIcon} from '@Assets/icons/espacio.svg';
import {ReactComponent as EmbajadaIcon} from '@Assets/icons/embajada.svg';
import {ReactComponent as MiniculIcon} from '@Assets/icons/minicul.svg';
import {ReactComponent as IrradiaIcon} from '@Assets/icons/logoirradiablanco.svg';
import { routes } from '../../routeConfig';


import './index.scss';

export default function Footer (props: any){
    return (
        <div className='c_footer'>
            <div className='c_footer__wrapper'>
                <div className='c_footer__wrapper_buttons'>
                    <div className='c_footer__buttons'>
                        <Button link={routes.ApoyaMali.path} theme='dark'>
                            APOYAR
                        </Button>
                        <Button link={routes.AspectoLegal.path} theme='solid'>
                            LEGAL
                        </Button>
                    </div>
                    <Button link={routes.QuienesSomos.path} className='c_footer__button' theme='solid'>
                        MÁS INFORMACIÓN
                    </Button>
                </div>
                <a href="https://mali.pe/" target="_blank">
                    <LogoMaliIcon className="c_footer__logo"/>
                </a>
            </div>
            <div className="c_footer__wrapper c_footer__wrapper__partners">
                <div className="c_footer__partners">
                    <p className="e-p3">Con el apoyo de :</p>
                    <div className="c_footer__partners_list c_footer__partners_list__Colab">
                        <a className="" href="https://www.gob.pe/cultura" target="_blank">
                            <MiniculIcon className="e-icon-h-3x e-icon-h-4x:md c_footer__partners_list_image"/>
                        </a>
                        <a className="" href="https://museos.cultura.pe/" target="_blank">
                            <MuseosLinea className="e-icon-h-6x e-icon-h-4x:md c_footer__partners_list_image"/>
                        </a>
                        <a className="" href="https://www.eda.admin.ch/lima" target="_blank">
                            <EmbajadaIcon  className="e-icon-h-6x e-icon-h-8x:md c_footer__partners_list_image"/>
                        </a>
                    </div>
                </div>
                <div className="c_footer__partners">
                    <p className="e-p3">En colaboración con :</p>
                    <div className="c_footer__partners_list">
                        <a className="" href="https://espacio.fundaciontelefonica.com.pe/evento/proyecto-irradia/" target="_blank">
                            <IrradiaIcon className=" e-icon-6x e-icon-10x:md"/>
                        </a>
                        <a className="" href="https://espacio.fundaciontelefonica.com.pe/" target="_blank">
                            <EspacioIcon className="e-icon-white e-icon-4x e-icon-6x:md"/>
                        </a>
                        <a className="" href="https://www.fundaciontelefonica.com.pe/" target="_blank">
                            <MovistarIcon className="e-icon-white e-icon-10x e-icon-12x:md"/>
                        </a>
                    </div>
                </div>
                
            </div>
            
        </div>
    )
}