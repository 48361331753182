import React from 'react';
import ContentLoader from 'react-content-loader'
import './index.scss';

interface iProps {
    width: number
}

export default (props: iProps) => {
    return (
        <ContentLoader 
            speed={4}
            height={400}
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"   
        >
            <rect x={0} y="10" rx="3" ry="3" width="100%" height="250" />
            <rect x={0} y="270" rx="3" ry="3" width="100%" height="20" />
            <rect x={0} y="300" rx="3" ry="3" width="100%" height="20" />
            <rect x={0} y="330" rx="3" ry="3" width="100%" height="50" />
        </ContentLoader>
    )
}