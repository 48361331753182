import React from 'react'
import {ReactComponent as LeftArrowIcon} from '@Assets/icons/left-arrow.svg'
import { useNavigate } from "react-router-dom";

import './index.scss'

interface iProps {
    title: string;
    theme?: string;
}

export const Navegation = function (props: iProps) {
    const navigate = useNavigate();
    return (
        <div className="c_navegation">
            <LeftArrowIcon className={`c_navegation__icon ${props.theme}`} onClick={()=>{navigate(-1);}}/>
            <h2 className={`c_navegation__title ${props.theme} e-title-bold`}>{props.title}</h2>
        </div>
    )
}