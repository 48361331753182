import React, {useContext} from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import { HeadContext } from '../../Provider';
import MasonryGrid from '../Grid';
import Loader from '../Loader';
import {iObra} from '../../Services/ObraServices';
import './index.scss';

interface iProps {
    handleClose?: () => void;
    handleLoadMore: ()=> void;
    list: iObra[];
    isLoading: boolean;
    notFound: boolean;
    total?: number;
    className?: string;
}

export default (props:iProps) => {

    const context = useContext(HeadContext);

    function hideSearch () {
        context.setShowSearch(false)
    }
    return (
        <div className={`c_working_list ${props.className}`}>
            {props.list.length > 0 ?
                <>
                {props.total &&
                    <p className="c_working_list__total_result e-p1">{`Se encontraron ${props.total} obras`}</p>
                }
                    <MasonryGrid collection={props.list} handleClickWork={()=> {hideSearch()}}></MasonryGrid>
                    {!props.notFound ?
                        <VisibilitySensor onChange={props.handleLoadMore}>
                            <Loader className="c_working_list__load_more"/>
                        </VisibilitySensor>
                        :
                        <p className={'e-h6'}>No se encontraron más resultados</p>
                    }
                </>
                :
                <>
                {props.isLoading && <Loader/>}
                {props.notFound && <p className={'e-h3'}>Lo sentimos, no encontramos resultados.</p>}
                </>
            }
        </div>
    )
}