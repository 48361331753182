import React, { useEffect, useState } from 'react'
import GA from '../../GoogleAnalytics';
import ColeccionList from '../../Components/ColeccionList';
import { useNavigate } from "react-router-dom";
import { routes } from '../../routeConfig';
import Loader from '../../Components/Loader';
import { Button } from '../../Components/Button';
import TematicoService, {iTematico} from '../../Services/TematicoService';
import './index.scss'

export const Home = () => {
    const navigate = useNavigate();
    const [listColeccion, setListColeccion ] = useState<iTematico[]>([]);
    const [ showSearch , setShowSearch ] = useState(false);
    const [ isLoading , setIsLoading ] = useState(false);

    function closeSearch () {
        setShowSearch(false);
    }

    function goTo (id: number) {
        navigate(`/tematicos/${id}`);
    }

    useEffect(() =>{
        setIsLoading(true);
        TematicoService.getAll().then( ({ data }: any) =>{
            setListColeccion(data.data.map((obj:iTematico)=>({...obj,portada:"/eje/"+obj.portada})))
        }).finally(
            setIsLoading(false)
        )
    },[])

    return (
        <div className="p_home">
            <GA.RouteTracker title={"Archivo Digital de Arte Peruano"}/>
            <div className="p_home__head">
                    {/* <div className="p_hpme__partners_list">
                        <a href="https://espacio.fundaciontelefonica.com.pe/evento/irradia-espacio-publico-proyectoganador/" target="_blank"><img src="./images/irradia.png"></img></a>
                        <a href="https://espacio.fundaciontelefonica.com.pe/" target="_blank"><MovistarIcon className="e-icon-10x"/></a>
                        <a href="https://www.fundaciontelefonica.com.pe/" target="_blank"><EspacioIcon className="e-icon-6x"/></a>
                        <a href="https://museos.cultura.pe/" target="_blank"><img src="./images/logo-ministerio-cultura.png"></img></a>
                        <a href="https://www.eda.admin.ch/lima" target="_blank"><EmbajadaIcon className="e-icon-10x"/></a>
                    </div> */}
                <div className="p_home__buttons">
                    <Button link={routes.Buscar.path} theme="solid">
                        BUSCAR RECURSOS
                    </Button>
                    <Button link={routes.RecursosPedagogicos.path} theme="linear">
                        RECURSOS PEDAGÓGICOS
                    </Button>
                </div>
            </div>
            { isLoading ?
                <Loader/>
                    :
                    <ColeccionList listColeccion={listColeccion} isClicked={(id)=>{goTo(id)}}></ColeccionList>
            }
        </div>
    )
}