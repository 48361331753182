import React from 'react';
import './index.scss';
import {iColeccion} from '../../Services/ColeccionServices';
import { Coleccion } from '../Coleccion';

interface iProps {
    listColeccion : iColeccion[];
    isClicked: (id: number)=>void;
}

export default (props: iProps) => {

    function isClicked (id: number) {
        props.isClicked(id);
    }
    
    return (
        <div className={`c_coleccion_list`}>
            {props.listColeccion && props.listColeccion.length > 0 &&
                props.listColeccion.map( (item: iColeccion, index: number) => {
                    return (<Coleccion 
                                isSecondary={ index % 2 == 0 } 
                                key={index}
                                title={item.nombre}
                                banner={item.portada}
                                isBlocked={item.subcolecciones?.length == 0}
                                isClicked={()=>{isClicked(item.id)}}
                            ></Coleccion>)
                })
            }
        </div>
    )
}