import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";

import Select from 'react-select';
import { Button } from '../Button';
import ObraServices, {iObra,iFuncion, iPeriodo, iFormSearch} from '../../Services/ObraServices';
import AutorServices, {iAutor} from '../../Services/AutorServices';
import SearchResultPersistService, {iSearchResultStorage} from '../../Services/SearchResultPersistService';
import WorkList from '../WorkList';
import './index.scss';
let oSearchResultPersistService:SearchResultPersistService;

interface iProps {
    handleClose: () => void;
}

export default (props: iProps) => {
    const [workList, setWorkList ] = useState<iObra[]>([]);
    const [page, setPage ] = useState<number>(0);
    const [total, setTotal ] = useState<number>(0);
    const [autorList, setAutorList ] = useState<iAutor[]>([]);
    const [funcionesList, setFuncionesList] = useState<iFuncion[]>([]);
    const [periodosList, setPeriodosList] = useState<iPeriodo[]>([]);
    const [notFound, setNotFound] = useState<boolean>(false); 
    const [form, setForm] = useState<iFormSearch>({});
    const [isLoading, setIsLoading] = useState<boolean>(false);

    function handleForm(event:any) {
        let inputName = event.target.name;
        let inputValue = event.target.value;
        setForm({...form, [inputName]: inputValue})
    }

    function handleSelect(event:any, name:string) {
        setForm({...form, [name]: event})
    }

    function postSearch(reset:boolean=true){
        oSearchResultPersistService.setForm(form)
        setIsLoading(true);
        const nextPage = page +1
        ObraServices.postSearch(ObraServices.castToBodySearch(form), reset ? 0 : nextPage).then( ({ data }: any) =>{
            const list:iObra[] = data?.data
            const total:number = data.pagination?.total
            if(list.length > 0){
                const result = reset ? list : workList.concat(list)
                setTotal(total)
                setPage(nextPage);
                setWorkList(result);
                oSearchResultPersistService.setResult(result,total,nextPage);
            }else{
                setNotFound(true);
            }
        }).catch({}).finally(()=>{setIsLoading(false)});
    }

    function clickSearch () {
        setWorkList([]);
        setPage(0);
        setNotFound(false);
        postSearch();
    }

    function loadMore(){
        postSearch(false);
    }

    function loadFromStorage(){
        const storage:iSearchResultStorage = oSearchResultPersistService.syncStorage() as iSearchResultStorage
        setWorkList(storage?.result || [])
        setTotal(storage?.total || 0)
        setPage(storage?.page || 0)
    }

    useEffect(() => {
        oSearchResultPersistService = SearchResultPersistService.getInstance()
        ObraServices.getFunciones().then( ({ data }: any) =>{
            setFuncionesList(data)
        })
        ObraServices.getPeriodos().then( ({ data }: any) =>{
            setPeriodosList(data)
        })
        AutorServices.getAll().then(({data}:any) =>{
            let listResult:any = [];
            if(data.data){
                data.data.forEach((element:any) => {
                    listResult = listResult.concat(element)
                });
                setAutorList(listResult)
            }
        })

        loadFromStorage()
    },[0])

    return (
        <div className="c_search">
            {/* <div className="c_search__head">
                <img className="c_search__head_logo" src="/images/logo.png"/>
                <CloseIcon onClick={hideSearch} className="c_search__head_close"/>
            </div> */}
            <div className="c_search__content">
                <h2 className="c_search__title e-text-bold">¿QUÉ ESTÁS BUSCANDO?</h2>
                <input value={form.titular || ""} name="titular" onChange={handleForm} type="text" className="c_search__input_title e-p1" placeholder="BUSCAR RECURSOS POR TÍTULO"></input>
                <div className="c_search__form">
                    <div className="c_search__form_input_group">
                        <p className="c_search__form_input_group_label e-p3 e-p5:md e-text-medium">MEDIO</p>
                        <Select value={form.funcion_genero} placeholder="SELECCIONAR" isClearable={true} noOptionsMessage={({inputValue})=>"no encontrado"} className="c_search__form_input_group_select" classNamePrefix="c_search__form_input_group_select" onChange={(selected)=>{handleSelect(selected, "funcion_genero")}} options={funcionesList.map((func)=>{return{value:func.codigo,label:func.nombre}})} />
                    </div>
                    <div className="c_search__form_input_group">
                        <p className="c_search__form_input_group_label e-p3 e-p5:md e-text-medium">ÉPOCA</p>
                        <Select value={form.periodo} isSearchable={false} isClearable={true} placeholder="SELECCIONAR" className="c_search__form_input_group_select" classNamePrefix="c_search__form_input_group_select" onChange={(selected)=>{handleSelect(selected, "periodo")}} options={periodosList.map((pre)=>{return{value:pre.abreviatura,label:pre.nombre}})} />
                    </div>
                    <div className="c_search__form_input_group">
                        <p className="c_search__form_input_group_label e-p3 e-p5:md e-text-medium">AUTOR</p>
                        <Select value={form.autor_id} placeholder="SELECCIONAR" isClearable={true} noOptionsMessage={({inputValue})=>"no encontrado"} className="c_search__form_input_group_select" classNamePrefix="c_search__form_input_group_select" onChange={(selected)=>{handleSelect(selected, "autor_id")}} options={autorList.map((autor)=>{return{value:String(autor.id),label:`${autor.nombres.trim()} ${autor.apellidos.trim()} `}})} />
                    </div>
                </div>
                <div className="c_search__button_wrapper">
                    <Button action={()=>{clickSearch()}} className="c_search__button" theme="solid">BUSCAR</Button>
                </div>
            </div>
            <WorkList
                className="c_search__result"
                list={workList}
                total={total}
                notFound={notFound}
                isLoading={isLoading}
                handleLoadMore={loadMore}
            />
        </div>   
    )
}