import { iInputModel, FormValidationHelper, iSelectModel } from './../Helpers/FormValidatorHelper';

export class AdquisitionModel extends FormValidationHelper {

    inpName: iInputModel = {
        name: "inpName",
        isRequired: true,
        autoFocus: true,
    };

    inpEmail: iInputModel = {
        name: "inpEmail",
        isRequired: true,
        autoFocus: true,
    };

    sltObjetive: iSelectModel<string> = {
        name: "sltObjetive",
        isRequired: true,
        value:"0",
        keyCode: "codeText",
        keyText: "name"
    };

    txtEspecification: iInputModel = {
        name: "txtEspecification",
        isRequired: false,
    };
    
}