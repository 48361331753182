import React, {useEffect, useState} from 'react';
import { useParams } from "react-router-dom";
import ObraServices, { iObra } from '../../Services/ObraServices';
import AutorServices, { iAutor } from '../../Services/AutorServices';
import { Navegation } from '../../Components/Navegation';
import GA from '../../GoogleAnalytics';
import WorkList from '../../Components/WorkList';
import Loader from '../../Components/Loader';
import './index.scss';
export default () => {
    const { id } = useParams<{id: string}>();
    const [total, setTotal ] = useState<number>(0);
    const [autor, setAutor] = useState<iAutor>()
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isPageLoading, setIsPageLoading] = useState<boolean>(true);
    const [workList, setWorkList ] = useState<iObra[]>([]);
    const [page, setPage ] = useState<number>(0);
    const [notFound, setNotFound] = useState<boolean>(false);

    useEffect(()=>{
        AutorServices.getById(id!).then(({data}:any)=>{
            const dataAutor = data.data[0]
            setAutor(dataAutor)
            setIsPageLoading(true);
            getLoad(true)
        }).catch(()=>{
            setIsPageLoading(false);
            setNotFound(true)
        })
    },[])
    
    function loadMore(){
        getLoad(false);
    }

    function getLoad(reset:boolean=false){
        setIsLoading(true);
        ObraServices.postSearch({autor_id:id}, reset ? 0 : page +1).then( ({ data }: any) =>{
            const list:iObra[] = data?.data
            const total:number = data.pagination?.total
            if(list.length > 0){
                setTotal(total)
                setPage(page + 1);
                setWorkList(reset ? list : workList.concat(list));
            }else{
                setNotFound(true);
            }
        }).catch({}).finally(()=>{setIsLoading(false);setIsPageLoading(false);});
    }

    return (
        <div>
            {!isPageLoading && autor? 
                <>
                    <GA.RouteTracker title={`Autor: ${autor.nombres} ${autor.apellidos}`}/>
                    <Navegation title={`Autor: ${autor.nombres} ${autor.apellidos}`} />

                    <WorkList
                        className="p_tematico__result"
                        list={workList}
                        total={total}
                        notFound={notFound}
                        isLoading={isLoading}
                        handleLoadMore={loadMore}
                    />
                </>
                :
                <Loader/>
            }
        </div>
    )
}