import React from 'react';
import Summary from '../Summary';
import './index.scss';
export interface iColeccion{
    nombre: string;
    codigo:string;
    medios: Array<{nombre:string , codigo:string}>;
    clicked?: (col:string, med:string)=> void;
}

export default (props:iColeccion) => {
    return (
        <Summary title={`Colecciones ${props.nombre}`}>
            <ul className="c_accordion__list">
                {props.medios &&
                    props.medios.map((medio, index) =>{
                        return (
                            <li key={index} onClick={()=>{if(props.clicked){props.clicked(props.codigo,medio.codigo)}}} className="c_accordion__list_item e-p3 e-text-regular">{medio.nombre}</li>
                        )
                    })
                }
            </ul>
        </Summary>
    )
}