import React, {useContext, useEffect, useState} from 'react';
import { Button } from '../Button';
import WorkLoader from '../WorkLoader';
import { HeadContext } from '../../Provider';
import { iObra } from '../../Services/ObraServices';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import './index.scss';

interface iProps {
    model: iObra
    handleClickInfo: any
}

export default (props: iProps) => {
    const [isLoaded,setIsLoaded] = useState(true)
    const [isLoading,setIsLoading] = useState(true)
    const [src, setSrc] = React.useState("");
    const context = useContext(HeadContext);
    useEffect(()=>{
        const img = new Image();
        img.src = `https://test.archi.pe/cdn/thumbs/obra/${props.model.imagen}`;
        img.onload = () => {
            setSrc(`https://test.archi.pe/cdn/thumbs/obra/${props.model.imagen}`);
            setIsLoading(false)
            setIsLoaded(true)
        };
        img.onerror = () => {
            setIsLoading(false)
            setIsLoaded(false)
        };
    }, [])

    function onLoaded() {
        setIsLoaded(true)
    }

    function onErrorLoad() {
        setIsLoaded(false)
        setIsLoading(false)
    }

    return (
        isLoaded ?
            <>
                {isLoading ?
                <div style={{textAlign:"center"}}>
                    <WorkLoader width={300}/>
                </div>
            :
                    <div className="c_work">
                        <img
                                    className="c_work__image"
                                    src={src}
                                    alt={props.model.titular}
                                    height={isLoading ? 0 : "auto"}
                                    onLoad={onLoaded}
                                    onError={onErrorLoad}
                        />
                        <p className="c_work__title e-p1 e-p2:md e-p4:lg e-p5:xl">{props.model.titular}</p>
                        <div className="c_work__detail">
                            <p className="c_work__date e-p2 e-p3:md e-p4:lg e-p5:xl">{props.model.fecha_epoca}</p>
                            <p className="c_work__author e-p2 e-p3:md e-p4:lg e-p5:xl">{props.model.autor_nombre} {props.model.autor_apellido && props.model.autor_apellido.trim().length > 0 ? `${props.model.autor_apellido}`: ""}</p>
                        </div>
                        <Button className="c_work__button" link={`/obra/${props.model.id}`} theme="black" prelink={()=>{ context.setShowSearch(false) }}>MÁS INFORMACIÓN</Button>
                    </div>
                }
            </>
        :
        <></>
    )
}