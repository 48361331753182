import React from 'react';
import './index.scss';
import { ReactComponent as ArrowIcon } from '@Assets/icons/next.svg';
interface iProps {
    title: string;
    children: React.ReactElement;
    keepOpen?: boolean;
}

export default (props: iProps) => {
    return (
        <details open={props.keepOpen} className="c_summary__detail">  
            <summary className="c_summary__title"><p className="e-h4 e-h6:md">{props.title}</p><span className="c_summary__title_icon"><ArrowIcon/></span></summary>  
            {props.children}
        </details>
    )
}