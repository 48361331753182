import React from 'react';
import SearchComponent from '../../Components/Search';
import GA from '../../GoogleAnalytics';
import './index.scss';
export default () => {
    return (
        <div>
            <GA.RouteTracker title={`Buscar`}/>
            <SearchComponent handleClose={()=>{}}></SearchComponent>
        </div>
    )
}