import api from './HttpUtil';

export interface iObra {
    id: number;
    autor_id: number;
    fotografo_id: number;
    usuario_creacion: number;
    usuario_modificacion: number;
    titular: string;
    descriptores_tematicos: Array<string>;
    descriptores_topograficos: Array<string>;
    descriptores_onomastico: Array<string>;
    descriptores_ocultos: Array<string>;
    fecha_epoca: string;
    materiales_tecnicas: string;
    altura: string;
    ancho: string;
    profundidad: string;
    diametro: string;
    funcion_genero: string;
    descripcion_obra: string;
    inscripciones: string;
    periodo: string;
    observaciones_contenido: string;
    creditos_fotografia: string;
    codigo_origen: number;
    indice_codigo_origen: number;
    comitente: string;
    fecha_toma_imagen: string;
    fecha_creacion_imagen: string;
    proceso_fotografico: string;
    soporte_secundario: string;
    ubicacion: string;
    fondo_coleccion: string;
    derechos_autor_pieza: 0;
    derechos_autor_imagen: 0;
    derechos_propietario_pieza: 0;
    estado_conservacion: string;
    historia_archivistica: string;
    documentacion_asociada: string;
    original: number;
    nivel_descripcion: string;
    notas_forma: string;
    catalogador_id: number;
    publicar: number;
    editor: string;
    coleccionista: string;
    codigo_antiguo: string;
    imagen: string;
    autor_nombre?: string,
    autor_apellido?: string,
    fotografo_nombre?: string,
    fotografo_apellido?: string,
    ejes?: Array<{obra_id: number,eje_tematico_id: number}>,
    medidas?: string
}

export interface iFuncion {
    codigo: string,
    nombre: string
}

export interface iPeriodo {
    abreviatura: string,
    nombre: string,
    codigo: number,
    lastCode: number
}

export interface iBodySearch {
    funcion_genero?: string;
    periodo?: string;
    autor_id?: string;
    titular?: string;
    descriptores_tematicos?:string;
}

export interface iFormSearch {
    funcion_genero?: {label:string, value: string};
    periodo?: {label:string, value: string};
    autor_id?: {label:string, value: string};
    titular?: string;
    descriptores_tematicos?:string;
}

const ObraService = {
    getByTematico(tematico: number, page:number=0,cant:number=10){
        return api.get('/obra/tematico/'+ tematico, {params:{page, cant}});
    },
    getById(id: string){
        return api.get('/obra/'+id);
    },
    getByPeriodoMedio(periodo: string , medio: string, page:number=0,cant:number=10){
        return api.get(`/obra/periodo/${periodo}/medio/${medio}`,{params:{page, cant}});
    },
    getFunciones(){
        return api.get('/funcion/all');
    },
    getPeriodos(){
        return api.get('/periodo/');
    },
    postSearch(body:iBodySearch, page:number=0,cant:number=10){
        return api.post('/obra/search',body,{params:{page, cant}});
    },
    castToBodySearch(form:iFormSearch): iBodySearch{
        return {
            funcion_genero: form.funcion_genero?.value,
            periodo: form.periodo?.value,
            autor_id: form.autor_id?.value,
            titular: form.titular,
            descriptores_tematicos: form.titular
        }
    }
}

export default ObraService;