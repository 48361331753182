import React, {useEffect, useState} from 'react';
import GA from '../../GoogleAnalytics';
import { CommonView } from '../../Layouts/CommonView';
import ParrafoServices, { iParrafo } from '../../Services/ParrafosServices';
import Loader from '../../Components/Loader';
import './index.scss'

export const AspectoLegal = function () {
    const [isLoading, setIsLoading] = useState(true)
    const [text, setText] = useState("")

    useEffect(()=>{
        ParrafoServices.getById("5").then((result: any)=> {
            setText(result.data[0]?.texto)
        }).finally(
            setIsLoading(false)
        )
    }, [])

    return (
        <div>
            {!isLoading ?
                <>
                <GA.RouteTracker title={"Aspecto Legal"}/>
                <CommonView title="ASPECTO LEGAL" content={
                    text ?
                    <div dangerouslySetInnerHTML={{__html: text}}></div>
                    :
                    <div>
                        Las fotografías e imágenes que forman parte de ARCHI- Archivo Digital de Arte Peruano están protegidas bajo el Derecho de Autor y el MALI cuenta con todos los derechos y autorizaciones correspondientes. Así, el MALI podrá permitir la reproducción de dichas fotografías en baja definición sin costo alguno por parte de los usuarios y público en general. La reproducción en alta definición de una fotografía se podrá permitir, previo pago correspondiente. Dicho pago corresponde a una suma simbólica destinada a cubrir gastos operativos y permitir la sostenibilidad del proyecto, de modo que no responde a finalidad lucrativa alguna del MALI.
                        <br></br><br></br>
                        Los permisos de reproducción otorgados por el MALI se extienden por única vez, de manera no exclusiva, y para uso con fines de investigación o enseñanza, debiendo respetarse en todo momento la integridad de la fotografía y por consiguiente no podrá ser modificada ni alterada. Toda cita o mención a la fotografía reproducida deberá estar acompañada de los créditos correspondientes (nombre de la imagen, identificación del autor o fotógrafo) así como la indicación destinada a informar que dicha fotografía corresponde a ARCHI - Archivo Digital de Arte Peruano. 
                        <br></br><br></br>
                        Finalmente, cualquier ulterior reproducción, comunicación pública, distribución, por cualquier medio o procedimiento, incluyendo Internet y medios digitales, que no corresponda a una finalidad de investigación o enseñanza, así como cualquier otro uso específico deberá contar con autorización previa y escrita del MALI.
                    </div>}
                />
                </>   
                : 
                <Loader/>
            }
        </div>
    )
}