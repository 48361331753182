import React, {useState, useEffect} from 'react';
import { Navegation } from '../../Components/Navegation';
import ResourceCollection, {iListRecursos} from '../../Components/ResourceCollection';
import { useParams } from "react-router-dom";
import Loader from '../../Components/Loader';
import ColeccionServices, {iColeccion} from '../../Services/ColeccionServices';
import RecursosServices, {iRecursos} from '../../Services/RecursosServices';
import GA from '../../GoogleAnalytics';
import './index.scss';
export default () => {
    const [listResourceAlumno, setListResourceAlumno] = useState<iListRecursos>();
    const [listResourceDocente, setListResourceDocente] = useState<iListRecursos>();
    const [coleccion, setColeccion ] = useState<iColeccion>();
    const [ isLoading , setIsLoading ] = useState(false);
    const { id , exp} = useParams<{id: string, exp: string}>();

    useEffect(() => {
        (
            async () => {
                setIsLoading(true)
                try {
                    const {data} = await ColeccionServices.getById(+id!)
                    if(data.data){
                        const proyecto = data.data[0]
                        const exposiciones = proyecto?.subcolecciones;
                        const exposicion = exposiciones.find((elm:iColeccion) =>elm.id == +exp!)
                        await loadContent(+exp!)
                        setColeccion(exposicion)
                    }
                } catch (error) {
                    
                } finally {
                    setIsLoading(false)
                }
            }
        )();
    }, [])

    async function loadContent (exposicion: number) {
        setListResourceAlumno({} as iListRecursos)
        setListResourceDocente({} as iListRecursos)
        const {data} = await RecursosServices.getByExposicion(exposicion)
        const listResourceAlumno:iListRecursos = {
            otro:data.data.filter((elm:iRecursos) => elm.tipo === "A" && (!elm.gradoAcademico || elm.gradoAcademico === "TOD")),
            inicial:data.data.filter((elm:iRecursos) => elm.tipo === "A" && elm.gradoAcademico === "INI"),
            primaria:data.data.filter((elm:iRecursos) => elm.tipo === "A" && elm.gradoAcademico === "PRI"),
            secundaria:data.data.filter((elm:iRecursos) => elm.tipo === "A" && elm.gradoAcademico === "SEC")
        } as iListRecursos
        setListResourceAlumno(listResourceAlumno)
        const listResourceDocente:iListRecursos = {
            otro:data.data.filter((elm:iRecursos) => elm.tipo === "D" && (!elm.gradoAcademico || elm.gradoAcademico === "TOD")),
            inicial:data.data.filter((elm:iRecursos) => elm.tipo === "D" && elm.gradoAcademico === "INI"),
            primaria:data.data.filter((elm:iRecursos) => elm.tipo === "D" && elm.gradoAcademico === "PRI"),
            secundaria:data.data.filter((elm:iRecursos) => elm.tipo === "D" && elm.gradoAcademico === "SEC")
        } as iListRecursos
        setListResourceDocente(listResourceDocente) 
    }

    function resetCheckbox(){
        loadContent(+exp!);
    }

    return (
        <div>
            { isLoading && !coleccion ?
            
            <Loader/>
            :
            <>
                { coleccion && coleccion.titulo &&
                    <GA.RouteTracker title={`Recursos Pedagógicos : ${coleccion!.titulo}`}/>
                }
                <Navegation title={`${coleccion?.titulo}`} />
                <p className="p_coleccion_educativa__pharagraph e-p4 e-text-light" dangerouslySetInnerHTML={{ __html:coleccion?.textoReferido || ''}}></p>
                <ResourceCollection reset={()=>{resetCheckbox()}} listResourceDocente={listResourceDocente} listResourceAlumno={listResourceAlumno}/>
            </>
    }
        </div>
    )
}