import React, {useEffect, useState} from 'react';
import GA from '../../GoogleAnalytics';
import { CommonView } from '../../Layouts/CommonView';
import ParrafoServices, { iParrafo } from '../../Services/ParrafosServices';
import Loader from '../../Components/Loader';
import './index.scss'

export const QuienesSomos = function () {
    const [isLoading, setIsLoading] = useState(true)
    const [text, setText] = useState("")

    useEffect(()=>{
        ParrafoServices.getById("4").then((result: any)=> {
            setText(result.data[0]?.texto)
        }).finally(
            setIsLoading(false)
        )
    }, [])

    return (
        <div>
            {!isLoading ?
                <>
                    <GA.RouteTracker title={"Quienes somos"}/>
                    <CommonView title="Quiénes somos" content={
                        <>
                            {text ?
                                <div dangerouslySetInnerHTML={{__html: text}}></div>
                                :
                                <p>
                                    ARCHIs. Archivo Digital de Arte Peruano es una plataforma dedicada a documentar y difundir el patrimonio cultural del Perú, de la forma más completa y representativa posible, contribuyendo a preservarlo y a hacerlo más accesible. 
                                    <br></br><br></br>
                                    Esta iniciativa del Museo de Arte de Lima – MALI y del Archivo Fotográfico Daniel Giannoni, en convenio con el Ministerio de Cultura del Perú, incluye imágenes de diversas expresiones de arte, cultura material y arquitectura del Perú, en sus diversos momentos históricos, desde la época prehispánica hasta el presente. Las imágenes en baja resolución pueden ser visualizadas y utilizadas de manera gratuita por estudiantes, profesores, investigadores y público en general para fines educativos y de investigación. El uso de imágenes con fines comerciales requiere autorización. 
                                    <br></br><br></br>
                                    La instalación y desarrollo de ARCHI ha sido posible gracias al generoso aporte de la Oficina Federal de Cultura de Suiza y de la Embajada Suiza en Lima. Esta alianza servirá para promover diversos proyectos de investigación que emprendan el Ministerio de Cultura y el MALI así como profesores, escolares, universitarios e investigadores de diversas instituciones públicas y privadas en el Perú y en el extranjero. 
                                    <br></br><br></br>
                                    ARCHI se concibe como un proyecto sostenido y abierto, que busca incorporar nuevos archivos a través de alianzas con diversos actores individuales e institucionales.
                                </p>
                            }   
                        </>
                        }
                    />
                </>
            :
                <Loader/>
            }
        </div>
    )
}