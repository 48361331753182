import React from 'react';
import { iInputModel } from './../../Helpers/FormValidatorHelper';
import { FormPattern } from './../../Helpers/FormPattern';
import './index.scss';

interface iProps{
    label: string;
    className: string;
    model:iInputModel;
    onChange?: (event: any) => void;
}

const Component:React.FunctionComponent<iProps> = (props:iProps) => {
  let oFormConstant = new FormPattern()
  let value = props.model.value || "";
  let type = props.model.type || "text";

    function handleChange(event: any) {
        let targetValue = event.target.value
        let targetType = event.target.type
    
        if (props.model.formatRegex) {
          targetValue = targetValue.replace(props.model.formatRegex, "");
        }
    
        if (targetType === "tel") {
          targetValue = targetValue.replace(/[^0-9]/g, "");
        }
    
        if (props.model.toUpperCase) {
          targetValue = targetValue.toUpperCase()
        }
    
        if (props.model.toLowerCase) {
          targetValue = targetValue.toLowerCase()
        }
        
        event.target.value = targetValue;
        props.onChange && props.onChange(event);
      }

    function getPattern() {
      let pattern: any
      switch (type) {
        case "email":
          pattern = oFormConstant.pattern.email
          break;
  
        default:
          pattern = props.model.pattern || null
          break;
      }
      return pattern
    }

    function isInvalid(): boolean {
      return props.model.isInvalid!
    }

    let classHasValue = value.length ? "c_input__wrapper__HasValue" : "";
    return (
        <div className={`c_input ${props.className}`}>
            <div className={`c_input__wrapper ${classHasValue} ${props.model.isInvalid ? "c_input__wrapper__IsInvalid" : ""}`}>
                <input className="c_input__wrapper_field"
                    name={props.model.name}
                    required={props.model.isRequired || false}
                    disabled={props.model.disabled || false}
                    type={type}
                    value={value}
                    pattern={getPattern()}
                    onChange={handleChange}
                    minLength={props.model.minLength}
                    maxLength={props.model.maxLength}
                >
                </input>
                <label className="c_input__label e-text-regular">{props.label}</label>
            </div>
        </div>
    )
}

export default Component;