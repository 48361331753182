import React from 'react';
import { Navegation } from '../../Components/Navegation';
import './index.scss'

interface iProps{
    title: string;
    content: JSX.Element;
}

export const CommonView = function (props : iProps) {
    return (
        <div>
            <Navegation title={props.title} />
            <div className="l_commonview__content e-text-light e-p2">
                {props.content}
            </div>
        </div>
    )
}