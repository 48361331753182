import React from 'react';
import Work from '../Work';
import './index.scss';
import { iObra } from '../../Services/ObraServices';
import Masonry from 'react-masonry-css';
interface iProps {
    collection: iObra[];
    handleClickWork?: any;
}

export default function MasonryGrid({ collection,handleClickWork }: iProps) {
      
    return (
    <div className="">
        <Masonry  breakpointCols={{default: 4, 768: 2 , 560: 1}}
                className="c_masonry_grid"
                columnClassName="c_masonry_grid_column">
        {collection &&
            collection.map((item, index) =>{
                return (
                    <Work key={index} model={item} handleClickInfo={()=>{ }}></Work>
                )
            })
        }
        </Masonry>
    </div>
    )
}
