import api from './HttpUtil';

export interface iRecursos {
    "id": number,
    "nombre": string,
    "file": string,
    "formato": string,
    "tipo": string,
    "publicar": number,
    "subcoleccion_id": number,
    "gradoAcademico": string,
    "orden": number,
    "subcoleccion": string,
    "coleccion_id": number,
    "coleccion": string
}


export default {
    getByExposicion(id: number){
        return api.get('/contenido/subcoleccion/'+id);
    },


    getCompressByIds(ids: Array<Number>){
        return api.post('/contenido/compress',{ids});
    }
}