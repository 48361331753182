import React, {  useCallback, useEffect, useState , useRef} from 'react';
import { Navegation } from '../../Components/Navegation';
import { useParams,Link } from "react-router-dom";
import Loader from '../../Components/Loader';
import { Button } from '../../Components/Button';
import Modal from '../../Components/Modal';
import {AdquisitionModel} from '../../Models/AdquisitionModel';
import { routes } from '../../routeConfig';
import { iOnChangeSelect } from './../../Helpers/FormValidatorHelper';
import { scrollTopDelay } from './../../Utils/ScrollTopUtil';
import InputComponent from '../../Components/Input'
import SelectComponent from '../../Components/Select'
import TextareaComponent from '../../Components/Textarea'
import GA from '../../GoogleAnalytics';
import ObraServices, { iObra } from '../../Services/ObraServices';
import ContactServices, { iContactRequest } from '../../Services/ContactServices';
import { Controlled as ControlledZoom } from 'react-medium-image-zoom';
import { ReactComponent as MailIcon } from '@Assets/icons/mail_sended.svg';
import { ReactComponent as MailErrorIcon } from '@Assets/icons/email_error.svg';
import {ReactComponent as MiniculIcon} from '@Assets/icons/minicul.svg';
import {ReactComponent as MuseosLinea} from '@Assets/icons/MUSEOS_LINEA_H_dark.svg';
import 'react-medium-image-zoom/dist/styles.css'
import './index.scss';

const SEARCH_TEXT_COINCIDENCE = 'MINISTERIO';

export const Obra = () => {

    const [form, setForm] = useState<AdquisitionModel>(new AdquisitionModel())
    const [obra, setObra] = useState<iObra>();
    const [isZoomed, setIsZoomed] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [isMINCUL, setIsMINCUL] = useState(false)
    const [loading, setLoading] = useState(false);
    const [loadingContact, setLoadingContact] = useState(false);
    const [statusSend, setStatusSend] = useState(false);
    const [statusSendError, setStatusSendError] = useState(false);
    const { id } = useParams<{id: string}>();
    const formRef:any = useRef(null);

    useEffect(() => {
        setLoading(true)
        ObraServices.getById(id!).then((result:any)=>{
            if(result.status === 200){
                const {data} = result.data;
                let medidas = ""
                if (data.altura && data.altura.trim().length > 0) medidas = medidas.concat(`${data.altura} x `)
                if (data.ancho && data.ancho.trim().length > 0) medidas = medidas.concat(`${data.ancho} x `)
                if (data.profundidad && data.profundidad.trim().length > 0) medidas = medidas.concat(`${data.profundidad} x `)
                medidas = medidas.slice(0, -3);
                if(medidas.trim().length > 0){
                    data.medidas = medidas.concat(" cm");
                }
                setObra(data);
                setLoading(false)
                const credito = data.creditos_fotografia.toUpperCase();
                const foundIt = credito.search(SEARCH_TEXT_COINCIDENCE);
                setIsMINCUL(foundIt > -1);
            }
        }).catch((err:any) => {console.error(err)}).finally(setLoading(false));
        scrollTopDelay(".c_header__nav");
    },[id]);
    
    const handleZoomChange = useCallback(shouldZoom => {
    setIsZoomed(shouldZoom)
    }, [])

    async function submitForm(e: any) {
        e.preventDefault();
        handleSubmit();
    }

    const handleInput = (event: any) => {
        setForm(form.getStateInput(form, event))
    }

    const handleSelect = (select: iOnChangeSelect) => {
        let currentForm = form.getStateSelect(form, select);
        setForm(currentForm)
    }

    const handleClose = () => {
        if(!loadingContact){
            setForm(new AdquisitionModel())
            setStatusSend(false)
            setStatusSendError(false)
            setShowModal(false)
        }
    }
    
    const handleSubmit = async () => {
        try {
            setLoadingContact(true)
            const currentForm = form.submit(form);
            setForm(currentForm);
            if(currentForm.isValid){
                const body: iContactRequest = {
                    nombre:form.inpName?.value,
                    email:form.inpEmail?.value,
                    uso: form.sltObjetive?.valueText,
                    obra:obra!.id
                }
        
                await ContactServices.postSend(body);
                setStatusSend(true)
            }
        } catch (error) {
            setStatusSendError(true) 
        } finally {
            setLoadingContact(false)
        }

    }

    const listFines = [
        {
            codeText:"0",
            name:"Fines de reproducción"
        },
        {
            codeText:"PUB",
            name:"Publicación"
        },
        {
            codeText:"CAT",
            name:"Catálogo"
        },
        {
            codeText:"EXP",
            name:"Exposición"
        },
        {
            codeText:"REV",
            name:"Revista"
        },
        {
            codeText:"MER",
            name:"Merchandising"
        },
        {
            codeText:"OTR",
            name:"Otros"
        }
    ]

    return (
        <div className="p_obra">
            {!loading  ?
            
                <>
                {obra ?
                    <>
                        <GA.RouteTracker title={`Obra - ${obra.titular}`}/>
                            <Navegation title={obra.titular} theme={"work"}/>
                            <div className="p_obra__content" id="p_obra__content">
                                <div className="p_obra__content_image">
                                    <ControlledZoom  isZoomed={isZoomed} onZoomChange={handleZoomChange}>
                                        <img  className={`${isZoomed ? "p_obra__content_image_img__Zoom":"p_obra__content_image_img"}`} src={`https://test.archi.pe/cdn/thumbs/obra/${obra.imagen}`}/>
                                    </ControlledZoom>
                                </div>
                                <div className="p_obra__content_info">
                                    {/* <p className="p_obra__content_info_date e-p4">{`${obra.indice_codigo_origen}.${obra.codigo_origen}`}</p> */}
                                    <p className="p_obra__content_info_author e-p2">
                                        <span className="p_obra__content_info_label e-p2 e-text-medium">Autor:</span>
                                        <br/>
                                        <Link to={routes.Autor.path.replace(":id", String(obra.autor_id))}>
                                            {obra.autor_nombre} {obra.autor_apellido && obra.autor_apellido.trim().length > 0 ? `${obra.autor_apellido}`: ""}
                                        </Link>
                                    </p>
                                    {obra.fecha_epoca && obra.fecha_epoca.trim().length > 0 &&
                                        <p className="p_obra__content_info_date e-p2">
                                            <span className="p_obra__content_info_label e-p2 e-text-medium">Periodo:</span>
                                            <br/> {obra.fecha_epoca}
                                        </p>
                                    }
                                    {obra.materiales_tecnicas && obra.materiales_tecnicas.trim().length > 0 && 
                                        <p className="p_obra__content_info_author e-p2">
                                            <span className="p_obra__content_info_label e-p2 e-text-medium">Materiales o Técnica:</span>
                                            <br/> {obra.materiales_tecnicas}
                                        </p>
                                    }
                                    {obra.medidas && 
                                        <p className="p_obra__content_info_dimension e-p2">
                                            <span className="p_obra__content_info_label e-p2 e-text-medium">Medidas:</span>
                                            <br/> {obra.medidas}
                                        </p>
                                    }
                                    {obra.coleccionista && obra.coleccionista.trim().length > 0 && 
                                        <p className="p_obra__content_info_title e-p2">
                                            <span className="p_obra__content_info_label e-p2 e-text-medium">Colección:</span>
                                            <br/> {obra.coleccionista}
                                        </p>
                                    }
                                    {obra.creditos_fotografia && obra.creditos_fotografia.trim().length > 0 && 
                                        <>
                                            <p className="p_obra__content_info_description e-p3">
                                                <span className="p_obra__content_info_label e-p2 e-text-medium">Créditos:</span>
                                                <br/> {obra.creditos_fotografia}
                                            </p>
                                            {isMINCUL &&
                                                <>
                                                    <div className='p_obra__content__Credits_wrapper'>
                                                        <a className="p_obra__content__Credits_wrapper__Link e-p1" href="https://visitavirtual.cultura.pe/" target="_blank">
                                                            Visita virtual
                                                        </a>
                                                        <a className="p_obra__content__Credits_wrapper__Link e-p1" href="https://museos.cultura.pe/" target="_blank">
                                                            Museos en Línea
                                                        </a>
                                                    </div>
                                                </>
                                            }
                                        </>
                                        
                                    }
                                    <p className="p_obra__content_info_photographer e-p2">
                                        <span className="p_obra__content_info_label e-p2 e-text-medium">FOTÓGRAFO:</span>
                                        <br/> {obra.fotografo_nombre} {obra.fotografo_apellido}
                                    </p>
                                    <div className="p_obra__content_info_buttons">
                                        <Button className="e-text-medium" theme={"black"} action={() => {setShowModal(true)}}>ADQUIRIR</Button>
                                    </div>
                                    <div className="p_obra__content_info_tags">
                                        {obra.descriptores_tematicos &&
                                            obra.descriptores_tematicos.map((item, index) =>{
                                            return (<Link to={routes.Descriptores.path.replace(":id", item)} key={index} className="e-p4">{item}</Link>)
                                                })
                                        }
                                    </div>
                                </div>
                                {showModal &&
                                    <Modal
                                        title={
                                            !statusSend && !statusSendError ?
                                            <>
                                            <h3 className="e-h3">Formulario de adquisición</h3>
                                            <br/>
                                            <p className="e-p2">Para obtener el derecho reproducción de esta imagen, deberá solicitarlo mediante este formulario: </p>
                                            </>: <></>
                                        }
                                        buttons={
                                            !statusSend && !statusSendError ?<>
                                                <Button theme='solid' isLoading={loadingContact} action={() =>{handleSubmit()}}>
                                                Solicitar
                                                </Button>
                                                <Button theme='linear' action={()=>{handleClose()}}>
                                                    Cerrar
                                                </Button>
                                            </>: <></>
                                        }
                                        handleClose={handleClose}
                                    >
                                        {!statusSend && !statusSendError &&
                                            <form ref={formRef} onSubmit={submitForm}>
                                                <InputComponent
                                                    className=""
                                                    label="Nombre y apellido"
                                                    model={form.inpName}
                                                    onChange={handleInput}
                                                />
                                                <InputComponent
                                                    className=""
                                                    label="Correo electrónico"
                                                    model={form.inpEmail}
                                                    onChange={handleInput}
                                                />
                                                <SelectComponent
                                                        className="p_home__form_input_group_item"
                                                        label="Fines de Reproducción*"
                                                        options={listFines}
                                                        model={form.sltObjetive}
                                                        onChange={handleSelect}
                                                />
                                                {form.sltObjetive.value == "OTR" &&
                                                    <TextareaComponent
                                                        className=""
                                                        label="Especifique"
                                                        model={form.txtEspecification}
                                                        onChange={handleInput}
                                                    />
                                                }
                                            </form>
                                        }
                                        {statusSend &&
                                            <>
                                                <div className="p_obra__modal_wrapper"><MailIcon className="p_obra__modal_icon"></MailIcon>
                                                <br/>
                                                <h3 className="e-h3 e-text-center">Solicitud en enviada</h3>
                                                <br/>
                                                <p className="e-p1 e-text-center">Pronto te enviaremos un correo a {form.inpEmail.value} en respuesta a tu solicitud </p>
                                                <br/>
                                                <Button theme='solid' isLoading={loadingContact} action={() =>{handleClose()}}>
                                                Entiendo
                                                </Button>
                                                </div>
                                            </>
                                        }
                                        {statusSendError &&
                                            <>
                                                <div className="p_obra__modal_wrapper"><MailErrorIcon className="p_obra__modal_icon"></MailErrorIcon>
                                                <br/>
                                                <h3 className="e-h3 e-text-center">Ocurrio al enviar la solicitud</h3>
                                                <br/>
                                                <p className="e-p1 e-text-center">Por favor inténtalo más tarde</p>
                                                <br/>
                                                    <Button theme='solid' isLoading={loadingContact} action={() =>{handleClose()}}>
                                                        Entiendo
                                                    </Button>
                                                </div>
                                            </>
                                        }
                                    </Modal>
                                }
                            </div>
                    </>
                    :
                    <h2 className="e-h4 e-text-center">Obra no encontrada</h2>
                }   
                </>
                :
                <Loader/>
            }
        </div>
    )
}