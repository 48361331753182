import api from './HttpUtil';


export interface iContactRequest {
    nombre: string,
    email: string,
    uso: string,
    obra: number
}


export default {
    postSend(requestBody: iContactRequest){
        return api.post('/contact/send', requestBody);
    },
}