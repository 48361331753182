import React, { useEffect, useState } from 'react';
import { ReactComponent as CloseIcon } from '@Assets/icons/close.svg';
import { ReactComponent as MenuIcon } from '@Assets/icons/menu.svg';
import {ReactComponent as LogoMaliIcon} from '@Assets/icons/MALI_White.svg';
import {ReactComponent as ARCHIIcon} from '@Assets/icons/ARCHI.svg';
import { HeadContext } from '../../Provider';
import { routes } from '../../routeConfig';
import { Link } from 'react-router-dom';
import './index.scss';
export default function Header (props:any) {
    const [ showMenu , setShowMenu ] = useState(false);
    const [ showSearch , setShowSearch ] = useState(false);

    function hideMenu () {
        setShowMenu(false);
    }

    return (
        <HeadContext.Provider value={{showSearch, setShowSearch}}>
            <div className={`c_header ${props.className}`}>
                <Link to={"/"} onClick={hideMenu} ><ARCHIIcon className="c_header__logo"/></Link>
                <a href="https://mali.pe/" target="_blank" >
                    <LogoMaliIcon className="c_header__logo c_header__logo__Mali e-hide-mobile"/>
                </a>
                <div className="c_header__nav_menu" onClick={()=>{setShowMenu(true)}}>
                    <MenuIcon width="25px" />
                </div>
            </div>
            <nav className={`c_header__nav ${showMenu ? 'show': ''}`}>
                    <Link to={routes.QuienesSomos.path} onClick={hideMenu} className="c_header__nav_item e-title-medium">QUIÉNES SOMOS</Link>
                    <Link to={routes.AspectoLegal.path} onClick={hideMenu} className="c_header__nav_item e-title-medium">ASPECTOS LEGALES</Link>
                    <Link to={routes.ApoyaMali.path} onClick={hideMenu} className="c_header__nav_item e-title-medium">APOYO AL MALI</Link>
                    <Link to={routes.Colecciones.path} onClick={hideMenu} className="c_header__nav_item e-title-medium">COLECCIONES</Link>
                    <Link to={routes.RecursosPedagogicos.path} onClick={hideMenu} className="c_header__nav_item e-title-medium">RECURSOS PEDAGÓGICOS</Link>
                    <Link to={`${routes.Buscar.path}`} onClick={hideMenu} className="c_header__nav_item e-title-medium">BUSCAR</Link>
                    <button className="c_header__nav_button_close" onClick={()=>{setShowMenu(false)}}>
                        <CloseIcon width="100%" />
                    </button>
            </nav>
        </HeadContext.Provider>
    )
}