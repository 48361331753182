export const scrollTop = (element: string, behavior?: string, block?: string) => {
  let domElement: any = document.querySelector(element)
  domElement.scrollIntoView({
    behavior: behavior || 'smooth',
    block: block || "start",
  });
}

export const scrollTopDelay = (element: string, behavior?: string, block?: string, time?: number) => {
  setTimeout(() => {
    scrollTop(element, behavior, block)
  }, time || 100)
}
