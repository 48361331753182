import api from './HttpUtil';


export interface iMedios {
    nombre: string,
    codigo: string
}


export interface iColeccion {
    id: number,
    nombre?: string,
    codigo?: string,
    medios?: iMedios[],
    titulo?: string,
    portada?: string,
    publicar?: number,
    orden?: number,
    usuario_creacion?: number,
    usuario_modificacion?: number,
    subcolecciones?:any,
    textoReferido?:string
}

export default {
    getAll(){
        return api.get('/coleccion/all');
    },

    getForWorks(){
        return api.get('/coleccion/forworks');
    },

    getById(id: number){
        return api.get('/coleccion/'+id);
    }
}