import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { routes } from './routeConfig';
import MainLayout from './Layouts/Main';
import GA from './GoogleAnalytics'
import './App.scss';

function App() {
  console.log('routes', routes)
  return (
    <Router>
      <div className="App">
        <MainLayout>
          <Routes>
            { GA.init() }
            { Object.values(routes).map(route => 
                <Route 
                  path={route.path}
                  key={route.title}
                  element={route.content}
                >

                </Route>
              )
            }
          </Routes>
        </MainLayout>
      </div>
    </Router>
  );
}

export default App;
