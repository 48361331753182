import { Home } from './Pages/Home'
import { QuienesSomos } from './Pages/QuienesSomos'
import { ApoyaMali } from './Pages/ApoyaMali'
import { Obra } from './Pages/Obra'
import { AspectoLegal } from './Pages/AspectoLegal'
import Colecciones from './Pages/Colecciones'
import Coleccion from './Pages/Coleccion'
import RecursosPedagogicos from './Pages/RecursosPedagogicos'
import ColeccionEducativa from './Pages/ColeccionEducativa'
import Tematico from './Pages/Tematico'
import Exposicion from './Pages/Exposicion'
import Descriptor from './Pages/Descriptor'
import Autor from './Pages/Autor'
import Buscar from './Pages/Buscar'
interface iParam {
    id: string;
    value: string;
}

interface iRoute {
    title: string;
    path: string;
    content: JSX.Element ;
    exact?: boolean;
    hide?: boolean;
    containParam?: boolean;
    params?: Array<iParam>;
}

interface iRoutes {
    [propName: string]: iRoute;
}

export const routes: iRoutes = {
    Home : { exact:true, path: '/', title:'Home' , content: <Home/> },
    QuienesSomos: { exact:true, path: '/quienes-somos', title:'Quienes Somos' , content: <QuienesSomos/> },
    AspectoLegal: { exact:true, path: '/aspecto-legal', title:'Aspecto Legal' , content: <AspectoLegal/> },
    ApoyaMali: { exact:true, path: '/apoya-mali', title:'Apoya al MALI' , content: <ApoyaMali/> },
    Obra: { exact:true, path: '/obra/:id', title:'Obra' , content: <Obra/> },
    Colecciones: { exact:true, path: '/colecciones', title:'Coleccion' , content: <Colecciones/> },
    Coleccion: { exact:true, path: '/coleccion/:col/:med', title:'Coleccion' , content: <Coleccion/> },
    RecursosPedagogicos: { exact:true, path: '/recurospedagogicos/', title:'Recursos Pedagogicos' , content: <RecursosPedagogicos/> },
    ColeccionEducativa: { exact:true, path: '/recurospedagogicos/:id', title:'Recursos Pedagogicos Coleccion' , content: <ColeccionEducativa/> },
    Exposicion: { exact:true, path: '/recurospedgogicos/exposicion/:id/:exp', title:'Recursos Pedagogicos Exposicion' , content: <Exposicion/> },
    Tematicos: { exact:true, path: '/tematicos/:id', title:'Tematicos' , content: <Tematico/> },
    Descriptores: { exact:true, path: '/descriptor/:id', title:'Descriptor' , content: <Descriptor/> },
    Buscar: { exact:true, path: '/buscar', title:'Buscar' , content: <Buscar/> },
    Autor: { exact:true, path: '/autor/:id', title:'Autor' , content: <Autor/> },
}